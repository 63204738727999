import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { logout } from '../../Actions/authentication'
import { answerCustomChallenge, clearError, getCurrentSession, signIn, signUp } from '../../Actions/twofa'
import { useTimer } from '../../Hooks/useTimer'
// import PropTypes from 'prop-types'

import { MFA as MFAUI } from '../../UIComponents/MFA'

const MFA = () => {
  const dispatch = useDispatch()

  const { count, start } = useTimer()
  const [pin, setPin] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const matchRoute = useRouteMatch([
    '*'
  ])

  const { userEmail, mfaError, mfaSessionIsValid } = useSelector(({ user, authentication }) => {
    return {
      userEmail: user.username,
      mfaSessionIsValid: authentication.mfaSessionIsValid,
      mfaError: authentication.mfaError
    }
  })

  useEffect(() => {
    setIsLoading(true)
    if (!mfaSessionIsValid) {
      try {
        dispatch(signUp(userEmail))
        dispatch(signIn(userEmail))
      } catch (error) {
        console.log(error)
      }
    }
    setIsLoading(false)
  }, [])

  useEffect(() => {
    dispatch(getCurrentSession())
  }, [matchRoute?.url])

  const handleSubmit = () => {
    dispatch(answerCustomChallenge(pin))
    setPin('')
  }

  const handleCancel = () => {
    dispatch(logout())
  }

  const handleSetPin = (pin) => {
    dispatch(clearError())
    setPin(pin)
  }

  const handleReSend = async () => {
    start(30)
    setPin('')
    dispatch(signIn(userEmail))
    dispatch(clearError())
  }

  if (isLoading || mfaSessionIsValid || !matchRoute) {
    return null
  }

  return (
    <MFAUI
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      length={6}
      pin={pin}
      setPin={handleSetPin}
      error={mfaError}
      timer={count}
      handleReSend={handleReSend}
      email={userEmail}
    />
  )
}

MFA.propTypes = {}

export { MFA }
