import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { debitTab, creditTab, boletoTab, pixTab, bolepixTab, nupayTab } from './TemplatesTableDetailPlan'

import BlankState from '../../UIComponents/BlankState/BlankState'

const DetailPlanComponent = () => {
  const { planDetailsData, color } = useSelector(({ plans, custom }) => ({
    planDetailsData: plans.planDetailsData,
    color: custom.color
  }))

  const getTitlesTabs = () => {
    const feeDetailsKeys = Object.keys(planDetailsData.fee_details)
    const feeKeys = feeDetailsKeys.map(feeKey => {
      if (feeKey === 'debit') return 'Débito'
      if (feeKey === 'credit') return 'Crédito'
      if (feeKey === 'boleto') return 'Boleto Bancário'
      if (feeKey === 'pix') return 'Pix'
      if (feeKey === 'bolepix') return 'Boleto com pix'
      if (feeKey === 'nupay') return 'Nupay'
    })

    return feeKeys.sort()
  }

  const titlesTabs = getTitlesTabs()

  return (
    <Fragment>
      {planDetailsData.fee_details && typeof titlesTabs === 'object' && (
        <Fragment>
          {titlesTabs.includes('Débito') &&
            <Fragment>
              {debitTab(planDetailsData.fee_details.debit, color)}
            </Fragment>
          }

          {titlesTabs.includes('Crédito') &&
            <Fragment>
              {creditTab(planDetailsData.fee_details.credit, color)}
            </Fragment>
          }

          {titlesTabs.includes('Boleto Bancário') &&
            <Fragment>
              {boletoTab(planDetailsData.fee_details.boleto, color)}
            </Fragment>
          }

          {titlesTabs.includes('Pix') &&
            <Fragment>
              {pixTab(planDetailsData.fee_details.pix, color)}
            </Fragment>
          }

          {titlesTabs.includes('Boleto com pix') &&
            <Fragment>
              {bolepixTab(planDetailsData.fee_details.bolepix, color)}
            </Fragment>
          }

          {titlesTabs.includes('Nupay') &&
          <Fragment>
            {nupayTab(planDetailsData.fee_details.nupay, color)}
          </Fragment>
          }
        </Fragment>
      )}
      {typeof titlesTabs === 'string' && (
        <BlankState icon='/assets/icons/others/tax.svg' message='Nenhuma taxa vinculada ao plano' />
      )}
    </Fragment>
  )
}

export default DetailPlanComponent
