import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { ProfileTypes } from '../../Constants/ProfileTypes'

import HeaderSidebar from '../../UIComponents/HeaderSidebar/HeaderSidebar'
import Grid from '../../UIComponents/Grid/Grid'
import Collapse from '../../UIComponents/Collapse/Collapse'
import ProfilesDetails from '../../UIComponents/ProfilesDetails/ProfilesDetails'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'

const ProfilesComponent = ({ handleClose }) => {
  const [collapsedSections, setCollapsedSections] = useState([])

  const handleCollapse = section => {
    if (collapsedSections.includes(section)) {
      const newSections = collapsedSections.filter(item => item !== section)
      return setCollapsedSections(newSections)
    }
    return setCollapsedSections([...collapsedSections, section])
  }

  const close = () => {
    handleClose()
    setCollapsedSections([])
  }

  return (
    <Fragment>
      <HeaderSidebar
        title={<><strong>Perfis</strong> de acesso</>}
        handleClose={close}
      />
      <SidebarContainer>
        {ProfileTypes.map(profile => (
          <Grid.Row auto noMargin key={profile.value} >
            <Collapse
              title={<strong>{profile.label}</strong>}
              isOpen={collapsedSections.includes(profile.value)}
              onClick={() => handleCollapse(profile.value)}
              data-test={`profile-collapse-${profile.value}`}
            >
              <ProfilesDetails profile={profile} />
            </Collapse>
          </Grid.Row>
        ))}
      </SidebarContainer>
    </Fragment>
  )
}

ProfilesComponent.propTypes = {
  handleClose: PropTypes.func
}

export default ProfilesComponent
