import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSellersToPlan, resetSetSellerToPlan, getSellersByPlan } from '../../Actions/plans'

import { SelectSeller } from '../SelectSeller/SelectSeller'

import Card from '../../UIComponents/Card/Card'
import Grid from '../../UIComponents/Grid/Grid'
import SubTitle from '../../UIComponents/Title/SubTitle'
import Button from '../../UIComponents/Button/ButtonDefault'
import DisplaySeller from '../../UIComponents/DisplaySeller/DisplaySeller'
import { MFASingleComponent } from '../MFA/mfaSingleComponent'

const AddSellerToPlan = () => {
  const dispatch = useDispatch()
  const [seller, setSeller] = useState(null)
  const [showMFA, setShowMFA] = useState(null)
  const [error, setError] = useState({ hasError: false, errorMessage: '' })

  const { planId, setSellerPlanRequesting, setSellerPlanSuccess } = useSelector(({ plans }) => ({
    planId: plans.planDetailsData.id,
    setSellerPlanRequesting: plans.setSellerPlanRequesting,
    setSellerPlanSuccess: plans.setSellerPlanSuccess
  }))

  useEffect(() => {
    if (setSellerPlanSuccess) {
      clearForm()
      dispatch(getSellersByPlan(planId))
      dispatch(resetSetSellerToPlan())
    }
  }, [ setSellerPlanSuccess ])

  const clearForm = () => {
    setSeller(null)
    setError({ hasError: false, errorMessage: '' })
  }

  const onSubmit = () => {
    if (!seller) {
      return setError({ hasError: true, errorMessage: 'Esqueceu de selecionar aqui?' })
    } else {
      setShowMFA(true)
    }
  }

  const onSubmitMFA = () => {
    dispatch(setSellersToPlan(planId, seller.id))
  }

  return (
    <>
      {showMFA && (
        <MFASingleComponent showComponent={setShowMFA} onSubmit={onSubmitMFA} />
      )}
      <Card>
        <Grid noPadding>
          <Grid.Row middleMarginBottom>
            <Grid.Col cols={2}>
              <SubTitle themeColor>Associar <strong>Estabelecimento ao Plano</strong></SubTitle>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row largeMarginBottom>
            <Grid.Col cols={5}>
              {!seller && <SelectSeller
                action={setSeller}
                hasError={error.hasError}
                errorMessage={error.errorMessage}
                handleError={() => setError({ hasError: false, errorMessage: '' })}
                planId={planId}
                border
                data-test='select-seller-plan'
                showSellerAssociatedBadge
              />}
              <Grid.Row />
              {seller && (
                <DisplaySeller seller={seller} onClick={() => setSeller(null)} data-test='config-plano-seller-display' />
              )}
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col cols={4}>
              <Button data-tracking={`AddSellerToPlan > Submit`} isLoading={setSellerPlanRequesting} onClick={() => onSubmit()} data-test='config-plano-associate-btn'>
              Associar Estabelecimento
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Card>
    </>
  )
}

export default AddSellerToPlan
