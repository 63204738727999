import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

import { changePeriod } from 'src/Actions/kpi'
import PeriodLabel from 'src/UIComponents/Label/PeriodLabel'
import DateInput from 'src/UIComponents/Date/DateInput'

import { UppercaseFirstLetter } from 'src/Utils/Utils'

const MetricsDateRangeSelector = () => {
  const dispatch = useDispatch()

  const { period } = useSelector(({ kpi: { period } }) => ({
    period
  }))

  const [selectedPeriod, setSelectedPeriod] = useState(period)

  const DEFAULT_PERIOD = {
    gte: moment().startOf('day').toDate(),
    lte: moment().endOf('day').toDate()
  }

  useEffect(() => {
    dispatch(changePeriod(selectedPeriod || DEFAULT_PERIOD))
  }, [selectedPeriod])

  const options = [
    {
      label: 'Hoje',
      value: {
        gte: moment().startOf('day').toDate(),
        lte: moment().endOf('day').toDate()
      }
    },
    {
      label: 'Ontem',
      value: {
        gte: moment().subtract(1, 'day').startOf('day').toDate(),
        lte: moment().subtract(1, 'day').endOf('day').toDate()
      }
    },
    {
      label: 'Essa semana',
      value: {
        gte: moment().startOf('isoWeek').toDate(),
        lte: moment().endOf('isoWeek').toDate()
      }
    },
    {
      label: 'Semana passada',
      value: {
        gte: moment().subtract(1, 'weeks').startOf('isoWeek').toDate(),
        lte: moment().subtract(1, 'weeks').endOf('isoWeek').toDate()
      }
    },
    {
      label: 'Esse mês',
      value: {
        gte: moment().startOf('month').toDate(),
        lte: moment().endOf('day').toDate()
      }
    },
    {
      label: 'Mês passado',
      value: {
        gte: moment().subtract(1, 'months').startOf('month').toDate(),
        lte: moment().subtract(1, 'months').endOf('month').toDate()
      }
    },
    {
      label: 'Até um ano',
      value: {
        gte: moment().subtract(1, 'year').startOf('day').toDate(),
        lte: moment().endOf('day').toDate()
      }
    }
  ]

  const location = useLocation().pathname.split('/')[1]
  const locationPath = UppercaseFirstLetter(location)

  const handleChange = (period) => {
    setSelectedPeriod(period)
    const periodOption = options.find((option) => option.value === selectedPeriod)
    let periodLabel = 'Data customizada'

    // eslint-disable-next-line no-unused-vars
    if (periodOption) periodLabel = periodOption.label
  }

  const handleClear = () => {
    setSelectedPeriod(DEFAULT_PERIOD)
  }

  return (
    <Wrapper data-test='select-date-kpi'>
      <DateInput
        placeholder={selectedPeriod && <PeriodLabel period={selectedPeriod} />}
        parentLabel={selectedPeriod && <PeriodLabel period={selectedPeriod} />}
        options={options}
        onSubmit={handleChange}
        onClear={handleClear}
        noPadding
        noBackground
        data-tracking={`${locationPath} > Selecionar periodo KPIs`}
        defaultValue={DEFAULT_PERIOD}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & .dropdown-toggle.btn,
  & .dropdown-toggle.btn:hover,
  & .dropdown-toggle.btn:active,
  & .dropdown-toggle.btn.active,
  & .dropdown-toggle.btn:not(:disabled):not(.disabled):active,
  & .dropdown-toggle.btn:not(:disabled):not(.disabled):active:focus,
  & .dropdown-toggle.btn.dropdown-toggle:focus,
  & .show > .dropdown-toggle.btn.dropdown-toggle,
  & .show > .dropdown-toggle.btn.dropdown-toggle:focus {
    border: 0 !important;
    padding: 1rem 0 !important;
    padding-left: 10px !important;
  }
`

export default MetricsDateRangeSelector
