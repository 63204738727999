import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { getPlanDetails, getSellersByPlan, resetPlanDetails } from '../../Actions/plans'
import { useHistory } from 'react-router-dom'

import SellersPlansComponent from '../SellersPlansComponent/SellersPlansComponent'
import AddSellerToPlan from './AddSellerToPlan'
import DetailPlanComponent from './DetailPlanComponent'

import Can from '../../Helpers/Permission/Can'
import Grid from '../../UIComponents/Grid/Grid'
import TitleInfoPage from '../../UIComponents/Title/TitleInfoPage'
import SubTitle from '../../UIComponents/Title/SubTitle'

import Sidebar from '../../UIComponents/Sidebar/Sidebar'

import PlanDetailsHeader from 'src/UIComponents/PlanDetails/PlanDetailsHeader'
import LineCard from 'src/UIComponents/LineCard/LineCard'

const PlansDetailComponent = ({ id }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [ showSidebar, setShowSidebar ] = useState(false)

  const {
    marketplaceId,
    planDetailsRequesting,
    planDetailsData,
    sellersPlanRequesting,
    totalSellers,
    planDetailsSuccess
  } = useSelector(({ marketplace, plans }) => ({
    marketplaceId: marketplace.details.id,
    planDetailsRequesting: plans.planDetailsRequesting,
    planDetailsData: plans.planDetailsData,
    sellersPlanRequesting: plans.sellersPlanRequesting,
    totalSellers: plans.totalSellers,
    planDetailsSuccess: plans.planDetailsSuccess
  }))

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getPlanDetails(marketplaceId, id))
    dispatch(getSellersByPlan(id))

    return () => dispatch(resetPlanDetails())
  }, [])

  return (
    <Fragment>
      <Sidebar show={showSidebar}>
        <SellersPlansComponent handleClose={() => setShowSidebar(false)} />
      </Sidebar>
      <Grid noPadding>
        <TitleInfoPage
          link
          data-tracking={`Config > Plans > Detail > Back`}
          onClick={() => history.push('/configuracoes/planos')}>&#60; Voltar para planos
        </TitleInfoPage>
        <PlanDetailsHeader
          isLoading={sellersPlanRequesting}
          planName={planDetailsSuccess && planDetailsData.name}
          setShowSidebar={setShowSidebar}
          totalSellers={totalSellers} />
        <Can I='update' a='Plan'>
          <Grid.Row largeMarginBottom>
            <Grid.Col offset={1} cols={15}>
              <AddSellerToPlan />
            </Grid.Col>
          </Grid.Row>
        </Can>
        <Grid.Row bigMarginBottom>
          <Grid.Col offset={1} cols={15}>
            <SubTitle line>
                Taxas <strong>do Plano</strong>
            </SubTitle>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col offset={1} cols={15}>
            {planDetailsRequesting && (
              <Fragment>
                <Grid.Row auto>
                  <LineCard status='neutral' isLoading />
                </Grid.Row>
                <Grid.Row auto>
                  <LineCard status='neutral' isLoading />
                </Grid.Row>
              </Fragment>
            )}
            {!planDetailsRequesting && planDetailsSuccess && <DetailPlanComponent />}
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Fragment>
  )
}

PlansDetailComponent.propTypes = {
  id: PropTypes.string
}

export default PlansDetailComponent
