import React from 'react'

import NavMenu from '../../UIComponents/NavMenu/NavMenu'
import Links from '../../Containers/Navigation/Links'
import { useGetPermissionProfile } from '../../Hooks/useGetPermissionProfile'

const NavMenuComponent = () => {
  const { getProfile } = useGetPermissionProfile()
  const profile = getProfile()

  return (
    <NavMenu links={Links.filter(link => !link.permissions || !link.permissions.hide.includes(profile))} />
  )
}

export default NavMenuComponent
