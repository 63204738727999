import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import InputForm from '../../UIComponents/InputForm/InputForm'
import styled from 'styled-components'
import Icon from 'src/UIComponents/Icon/Icon'

class PasswordInput extends Component {
  constructor (props) {
    super(props)
    this.state = { viewPassword: false }
  }

  handleViewPassword = () => {
    this.setState({ viewPassword: !this.state.viewPassword })
  }

  render () {
    const { id, label, value, placeholder, disabled, hasError, errorMessage, onChange } = this.props

    return (
      <Fragment>
        <InputForm style={{ position: 'absolute' }}
          id={id}
          type={this.state.viewPassword ? 'text' : 'password'}
          label={label}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          hasError={hasError}
          errorMessage={errorMessage}
          onChange={onChange}
        >
          <IconEye
            src={`/assets/icons/actions/${this.state.viewPassword ? 'hide' : 'show'}.svg`}
            onClick={() => this.handleViewPassword()}
            height={'17px'}
          />
        </InputForm>
      </Fragment>
    )
  }
}

PasswordInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string
}

const IconEye = styled(Icon)`
  position: absolute;
  right: 0;
  top: -10px;
`

export default PasswordInput
