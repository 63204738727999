import React from 'react'

import NavMenu from '../../UIComponents/NavMenu/NavMenu'
import SellerLinks from '../../Containers/Navigation/SellerMenu'
import { useGetPermissionProfile } from '../../Hooks/useGetPermissionProfile'
import { useSelector } from 'react-redux'

const NavMenuSellerComponent = () => {
  const { seller } = useSelector(({ context }) => context)
  const { getProfile } = useGetPermissionProfile()
  const profile = getProfile()

  const linksWithContext = () => {
    const links = []
    SellerLinks.map(link => {
      if (!link.permissions || !link.permissions.hide.includes(profile)) {
        links.push({
          ...link,
          to: `/estabelecimento/${seller.id}${link.to}`
        })
      }
    })

    return links
  }

  return (
    <NavMenu isSellerMenu links={linksWithContext()} />
  )
}

export default NavMenuSellerComponent
