import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { answerCustomChallengeWithoutSession, signIn } from '../../Actions/twofa'
import { useTimer } from '../../Hooks/useTimer'
import PropTypes from 'prop-types'

// import PropTypes from 'prop-types'

import { MFA as MFAUI } from '../../UIComponents/MFA'

const MFASingleComponent = ({ showComponent, onSubmit, isSidebar, email }) => {
  const dispatch = useDispatch()
  const [pin, setPin] = useState('')
  const [error, setError] = useState(null)

  const [isLoading, setIsLoading] = useState(true)

  const { count, start } = useTimer()

  const { userEmail } = useSelector(({ user }) => {
    return {
      userEmail: email || user.username
    }
  })

  useEffect(() => {
    setIsLoading(true)
    try {
      dispatch(signIn(userEmail))
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }, [])

  const handleSubmit = async () => {
    setIsLoading(true)
    const pinCodeRight = await dispatch(answerCustomChallengeWithoutSession(pin))
    if (pinCodeRight?.signInUserSession) {
      onSubmit()
      setIsLoading(false)
      showComponent(false)
    } else {
      setError({ message: 'Digite um código de confirmação válido' })
      setIsLoading(false)
    }
    setPin('')
  }

  const handleCancel = () => {
    // Hide Component
    showComponent(false)
  }

  const handleSetPin = (pin) => {
    setPin(pin)

    if (error) {
      setError(null)
    }
  }

  const handleReSend = async () => {
    start(30)
    setPin('')
    dispatch(signIn(userEmail))
  }

  return (
    <MFAUI
      length={6}
      error={error}
      pin={pin}
      setPin={handleSetPin}
      isLoading={isLoading}
      timer={count}
      handleReSend={handleReSend}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      email={userEmail}
      isSidebar={isSidebar}
    />
  )
}

MFASingleComponent.propTypes = {
  showComponent: PropTypes.func,
  onSubmit: PropTypes.func,
  isSidebar: PropTypes.bool,
  email: PropTypes.string
}

export { MFASingleComponent }
