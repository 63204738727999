import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import Floater from 'react-floater'
import styled from 'styled-components'

import { middleMargin, smallMargin, normalText, bigText, white } from 'src/Styles/settings/Constants'
import Label from '../../UIComponents/Label/Label'
import { setHintViewCheck } from 'src/Actions/onboarding'
import { hints } from './hints'
import Button from '../../UIComponents/Button/ButtonDefault'

export const Beacon = ({ hint: hintName, wrapperProps, ...props }) => {
  const showHint = useRouteMatch({
    path: ['/home', '*/visao-geral']
  })

  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [hint, setHint] = useState(null)

  const {
    themeColor,
    viewedHints,
    viewedHintsRequesting,
    hintGroup
  } = useSelector(({ custom, onboarding, context }) => ({
    themeColor: custom.color,
    viewedHints: onboarding.viewedHints,
    viewedHintsRequesting: onboarding.viewedHintsRequesting,
    hintGroup: context.seller ? 'seller' : 'marketplace'
  }))

  useEffect(() => {
    const currentHint = hints && hints.find(value => value.id === hintName && value.group === hintGroup)
    const viewedHint = currentHint && viewedHints && viewedHints.find(value => value.id === currentHint.id && value.group === hintGroup)

    if (!viewedHint) {
      setHint(currentHint)
    } else {
      setHint(null)
    }
  }, [viewedHints])

  const prevent = e => {
    e.preventDefault()
    e.stopPropagation()
    return true
  }

  const handleOpen = e => {
    prevent(e)
    setOpen(true)
  }

  const handleClose = e => {
    e.stopPropagation()
    setOpen(false)
  }

  const handleCallback = (e, action) => {
    if (action === 'close') {
      dispatch(setHintViewCheck([...viewedHints, hint], true))
    } else {
      const hintsToSet = hints.filter((value) => value.group === hintGroup)
      const hintsToSetWithoutRepeat = []
      hintsToSet.forEach((hintToSet) => {
        const alreadyExists = viewedHints.find(viewedHint => viewedHint.id === hintToSet.id && viewedHint.group === hintToSet.group)
        if (!alreadyExists) {
          hintsToSetWithoutRepeat.push(hintToSet)
        }
      })

      dispatch(setHintViewCheck([...viewedHints, ...hintsToSetWithoutRepeat], true))
    }

    handleClose(e)
  }

  const apiCallAlreadyFinished = !viewedHintsRequesting && viewedHints

  return (
    <>
      <Wrapper {...wrapperProps}>
        {
          apiCallAlreadyFinished && Boolean(hint) && showHint && (
            <>
              <Floater
                open={open}
                placement={hint.placement}
                {...props}
                styles={getStyles({
                  themeColor: themeColor
                })}
                content={
                  <ContentWrapper onClick={prevent}>
                    <Close onClick={handleClose} />
                    <Tooltip isNew={hint.isNew} title={hint.title} content={hint.content} />
                    <ButtonsContainer>
                      <Button widthAuto outline outlineColor='white' onClick={(e) => handleCallback(e, 'close')} >Concluir</Button>
                      <Button widthAuto link colorButton='white' onClick={(e) => handleCallback(e, 'closeAll')} >Fechar todos</Button>
                    </ButtonsContainer>
                  </ContentWrapper>
                }
              >
                <BeaconPosition onClick={handleOpen} className='hint-beacon' />
              </Floater>
              {open && <Overlay onClick={handleClose} />}
            </>
          )
        }
      </Wrapper>
    </>
  )
}

const Wrapper = styled.span`
  position: relative;
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
`

const ContentWrapper = styled.div`
  padding: 2rem;
`

const ButtonsContainer = styled.div`
  >Button {
    margin-right: 10px;
  }
`

const Overlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9998;
  cursor: default;

  *:not([onClick]) {
    pointer-events: none;
  }
`

const Close = styled.span`
  cursor: pointer;
  background-color: white;
  border: 0;
  border-radius: 0;
  color: white;
  font-size: 0;
  height: 15px;
  outline: none;
  padding: 10px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 15px;
  -webkit-mask: url('/assets/icons/actions/close.svg') no-repeat 50% 50%;
  mask: url('/assets/icons/actions/close.svg') no-repeat 50% 50%;
`

const BeaconPosition = styled.span`
  position: absolute;
  z-index: 3000;
`

const TooltipContainer = styled.div`
  margin-bottom: ${middleMargin};

  *:not([onClick]) {
    pointer-events: none;
  }
`

const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-top: 1.4rem;
  margin-bottom: 3rem;
`

const ContainerNew = styled.div`
  display: flex;
  margin-bottom: ${middleMargin};
`

const StarIcon = styled(ReactSVG)`
  margin-right: ${smallMargin};
`

const Tooltip = ({ isNew, title, content }) => (
  <TooltipContainer>
    {isNew && <ContainerNew>
      <StarIcon src='/assets/icons/others/star-outline.svg' />
      <Label color={white} fontSize={normalText} bold>NOVO!</Label>
    </ContainerNew>}
    <Label color={white} fontSize={bigText} bold>{title}</Label>
    <Text>{content}</Text>
  </TooltipContainer>
)

Tooltip.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  isNew: PropTypes.bool
}

const getStyles = (options) => {
  return ({
    wrapper: {
      cursor: 'pointer',
      display: 'inline',
      zIndex: 98
    },
    wrapperPosition: {
      left: 0,
      position: 'absolute',
      top: 0,
      visibility: 'visible'
    },
    floater: {
      display: 'inline-block',
      filter: 'drop-shadow(0 0 8px rgba(85, 85, 85, 0.2))',
      maxWidth: 333,
      opacity: 0,
      position: 'relative',
      transition: 'opacity 0.3s',
      visibility: 'visible',
      zIndex: 9999
    },
    floaterOpening: {
      opacity: 1,
      visibility: 'visible'
    },
    floaterWithAnimation: {
      opacity: 1,
      transition: 'opacity 0.3s, transform 0.2s',
      visibility: 'visible'
    },
    floaterWithComponent: {
      maxWidth: '100%'
    },
    floaterClosing: {
      opacity: 0,
      visibility: 'visible'
    },
    container: {
      color: '#fff',
      background: options.themeColor,
      // left: 110,
      borderRadius: '10px',
      padding: 0
    },
    title: {
      borderBottom: '1px solid #555',
      color: '#555',
      fontSize: 18,
      marginBottom: 5,
      paddingBottom: 6,
      paddingRight: 18
    },
    content: {
      fontSize: 15
    },
    close: {
      backgroundColor: white,
      border: 0,
      borderRadius: 0,
      color: white,
      fontSize: 0,
      height: 15,
      outline: 'none',
      padding: 10,
      position: 'absolute',
      right: 15,
      top: 15,
      width: 15,
      '-webkit-mask': 'url("/assets/icons/actions/close.svg") no-repeat 50% 50%',
      mask: 'url("/assets/icons/actions/close.svg") no-repeat 50% 50%'
    },
    arrow: {
      color: options.themeColor
    }
  })
}
