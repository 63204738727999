import React from 'react'
import PropTypes from 'prop-types'
import StyledPagination from 'src/UIComponents/Pagination/Pagination'
import PageItem from 'src/UIComponents/Pagination/PageItem'
import ArrowPageItem from 'src/UIComponents/Pagination/ArrowPageItem'
import { clamp } from 'lodash'

const Pagination = (props) => {
  const getSafePage = (page) => {
    if (Number.isNaN(page)) {
      page = props.page
    }
    return clamp(page, 0, props.pages - 1)
  }

  const changePage = (page) => {
    page = getSafePage(page)
    if (props.page !== page) {
      props.onPageChange(page)
    }
  }

  const applyPage = (e, page) => {
    e && e.preventDefault()
    changePage(page === '' ? props.page : page - 1)
  }

  const {
    // Computed
    pages,
    // Props
    page
  } = props

  const currentPage = page + 1
  const lastPage = pages
  const totalPages = pages
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === lastPage

  return (
    <StyledPagination>
      {/* Previous */}
      {!isFirstPage &&
        <ArrowPageItem onClick={(e) => applyPage(e, currentPage - 1)} direction='left'>
          ANTERIOR
        </ArrowPageItem>}

      <PageItem active={currentPage === 1} className={'first'} onClick={(e) => applyPage(e, 1)}>
          1
      </PageItem>

      {currentPage - 4 > 1 && <PageItem disabled>...</PageItem>}
      {/* END Previous */}

      {/* 2 Before current page */}
      {currentPage - 2 > 1 &&
        <PageItem onClick={(e) => applyPage(e, currentPage - 2)}>
          {currentPage - 2}
        </PageItem>}
      {currentPage - 1 > 1 &&
        <PageItem onClick={(e) => applyPage(e, currentPage - 1)} hideBorder>
          {currentPage - 1}
        </PageItem>}

      {/* Current page */}
      {!isLastPage && currentPage !== 1 &&
        <PageItem active onClick={(e) => applyPage(e, currentPage)}>
          {currentPage}
        </PageItem>}

      {/* 2 After current page */}
      {currentPage + 1 < totalPages &&
        <PageItem onClick={(e) => applyPage(e, currentPage + 1)}>
          {currentPage + 1}
        </PageItem>}
      {currentPage + 2 < totalPages &&
        <PageItem onClick={(e) => applyPage(e, currentPage + 2)} hideBorder>
          {currentPage + 2}
        </PageItem>}

      {/* Next */}
      {currentPage + 4 < totalPages && <PageItem disabled>...</PageItem>}

      {lastPage !== 1 &&
        <PageItem active={currentPage === lastPage} onClick={(e) => applyPage(e, lastPage)} hideBorder>{lastPage}</PageItem>}
      {!isLastPage &&
        <ArrowPageItem onClick={(e) => applyPage(e, currentPage + 1)}>
          PRÓXIMO
        </ArrowPageItem>}
      {/* END Next */}
    </StyledPagination>
  )
}

Pagination.defaultProps = {
  page: 0,
  pages: 1
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func
}

export default Pagination
