import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ExportNotification as ExportNotificationUI } from 'src/UIComponents/ExportNotification'
import {
  controlShowReportModal,
  getExportsList,
  deleteFile,
  redoFile,
  downloadFile,
  downloadAllFiles,
  resetAllFiles
} from 'src/Actions/exports'

const ExportNotification = () => {
  const dispatch = useDispatch()
  const [items, setItems] = useState([])
  const [hasError, setHasError] = useState(false)

  const handleDelete = item => {
    const isMarkup = item.type === 'commissions'
    dispatch(deleteFile(item.filename, item.type, isMarkup))
  }

  const handleRetry = item => {
    dispatch(redoFile(item.filename, item.type, item.extension))
  }

  const handleDownload = item => {
    dispatch(downloadFile(item.filename, item.type))
  }

  const handleDownloadAllFiles = () => {
    dispatch(downloadAllFiles())
  }

  const handleClearAll = () => {
    dispatch(resetAllFiles())
  }

  /**
   * This Function will get a date from filename property, and create an Date object.
   * @param {*} filename filename that will be extracted
   * @returns {Date} Date object converted from the string
   */
  const extractAndParseDate = (filename) => {
    // Check if the filename matches the date format
    // match1 - dia, match2 - mes, match3 - ano.
    // match4 - hora, match5 - minuto, match6 -segundo .
    const regex = /(\d{2})-(\d{2})-(\d{4})_(\d{2})(\d{2})(\d{2})/
    const match = filename.match(regex)
    if (match) {
      return new Date(match[3], match[2], match[1], match[4], match[5], match[6]).getTime()
    }
    return 0 // If the date is invalid or in another format return 0;
  }

  /**
   * Simple function to sort the data by date
   * @param {Array<Object>} groupedItems
   * @returns {Array<Object>} groupedItens Sorted by date from filename
   */
  const sortData = (groupedItems) => {
    const sortedByDate = groupedItems.sort((a, b) => {
      const dateA = new Date(extractAndParseDate(a.filename))
      const dateB = new Date(extractAndParseDate(b.filename))

      return dateB - dateA
    })
    return sortedByDate
  }

  const {
    showReportModal,
    watchFiles,
    exportsList,
    errorList,
    exportsListError
  } = useSelector(({ exports }) => exports)

  useEffect(() => {
    dispatch(getExportsList('all', [], true))
  }, [])

  useEffect(() => {
    const groupedItems = [...watchFiles, ...exportsList, ...errorList]

    const orderByData = sortData(groupedItems)

    setItems(orderByData)
    setHasError(errorList.length > 0)
  }, [watchFiles, exportsList, errorList])

  return (
    <ExportNotificationUI
      items={items}
      hasError={hasError}
      handleClose={() => dispatch(controlShowReportModal(false))}
      show={showReportModal}
      handleDelete={handleDelete}
      handleRetry={handleRetry}
      handleDownload={handleDownload}
      handleDownloadAllFiles={handleDownloadAllFiles}
      handleClearAll={handleClearAll}
      totalSucceeded={exportsList.length}
      totalRequested={exportsList.length + watchFiles.length}
      listError={exportsListError}
    />
  )
}

export { ExportNotification }
