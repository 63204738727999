import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import Proptypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import Label from 'src/UIComponents/Label/Label'
import Title from 'src/UIComponents/Title/Title'
import Grid from 'src/UIComponents/Grid/Grid'
import Button from 'src/UIComponents/Button/ButtonDefault'

import {
  darkGray,
  lightGray,
  headerHeight,
  spaceMargin
} from 'src/Styles/settings/Constants'
import { resetSellerContext } from '../../Actions/context'

const NotFoundPageComponent = ({ hasSlug }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const messagePrefix =
    'Não conseguimos encontrar o que você procurava, mas não se preocupe, acontece.'
  const messageDescription = hasSlug
    ? 'Que tal uma sugestão? Visite uma das páginas abaixo e veja se lhe ajuda.'
    : 'Verifique se o prefixo da URL está correto e tente novamente.'

  useEffect(() => {
    dispatch(resetSellerContext())
  }, [])

  return (
    <Wrapper hasSlug={hasSlug}>
      <ColumnLeft>
        <StyledLabelErrorCode>404</StyledLabelErrorCode>
      </ColumnLeft>
      <ColumnCenter />
      <ColumnRight>
        <Grid noPadding>
          <StyledGridRow>
            <StyledGridColumn cols={16}>
              <StyledTitle noPadding noTransform big>
                O que aconteceu?
              </StyledTitle>
            </StyledGridColumn>
          </StyledGridRow>
          <StyledGridRow>
            <StyledGridColumn cols={16}>
              <StyledLabelDescription>{messagePrefix}</StyledLabelDescription>
            </StyledGridColumn>
          </StyledGridRow>
          <StyledGridRow>
            <StyledGridColumn cols={16}>
              <StyledLabelDescription>
                {messageDescription}
              </StyledLabelDescription>
            </StyledGridColumn>
          </StyledGridRow>
        </Grid>
        {hasSlug && (
          <Grid noPadding>
            <StyledGridRow>
              <br />
              <StyledGridColumn cols={16}>
                <Button
                  widthAuto
                  onClick={() => history.push('/home')}
                  link
                  bold
                >
                  Home,
                </Button>
                <Button
                  widthAuto
                  onClick={() => history.push('/vendas')}
                  link
                  bold
                >
                  Vendas,
                </Button>
                <Button
                  widthAuto
                  onClick={() => history.push('/transferencias')}
                  link
                  bold
                >
                  {' '}
                  Transferências,
                </Button>
                <Button
                  widthAuto
                  onClick={() => history.push('/estabelecimentos')}
                  link
                  bold
                >
                  Estabelecimentos
                </Button>
              </StyledGridColumn>
            </StyledGridRow>
          </Grid>
        )}
      </ColumnRight>
    </Wrapper>
  )
}

NotFoundPageComponent.propTypes = {
  hasSlug: Proptypes.bool
}

NotFoundPageComponent.defaultProps = {
  hasSlug: true
}

const ColumnLeft = styled.div`
  float: left;
  width: 45%;
  margin: 40px;
  padding-left: 40px;
  height: 300px;
  width: 260px;
  display: flex;
  align-items: center;
`
const ColumnCenter = styled.div`
  float: left;
  width: 5%;
  height: 300px;
  width: 1px;
  background-color: ${lightGray};
`
const ColumnRight = styled.div`
  border: 5px;
  float: left;
  width: 50%;
  padding: 60px;
  height: 300px;
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const StyledGridRow = styled(Grid.Row)`
  margin: 2px;
`
const StyledGridColumn = styled(Grid.Col)`
  padding: 0px;
  display: block;

  button {
    margin-right: ${spaceMargin};
  }
`
const StyledTitle = styled(Title)`
  justify-content: left;
  align-items: left;
  content-align: left;
`
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  min-height: 100vh;
  padding: 8rem;

  ${({ hasSlug }) =>
    hasSlug &&
    css`
      margin-top: -${headerHeight};
      margin-bottom: -8rem;
    `}
`
const StyledLabelErrorCode = styled(Label)`
  font-size: 122px;
  font-weight: bolder;
  text-align: center;
`
const StyledLabelDescription = styled(Label)`
  font-size: 14px;
  color: ${darkGray};
  white-space: pre;
`

export default NotFoundPageComponent
