/* eslint-disable no-unused-vars */
/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react'
import { darkGray, green, yellow } from '../../Styles/settings/Constants'
import { getPlans, resetPlans } from '../../Actions/plans'
import { useDispatch, useSelector } from 'react-redux'

import Grid from '../../UIComponents/Grid/Grid'
import Label from '../../UIComponents/Label/Label'
import PropTypes from 'prop-types'
import { SearchInput } from 'src/UIComponents/SearchInput'
import SubTitle from '../../UIComponents/Title/SubTitle'
import Table from '../Table/NewTable'
import Title from '../../UIComponents/Title/Title'
import TitleInfoPage from '../../UIComponents/Title/TitleInfoPage'
import PlansDetailComponent from '../../Components/PlansDetailComponent/PlansDetailComponent'

import moment from 'moment'
import { useRouteMatch } from 'react-router-dom'

const PlansComponent = ({ history }) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [sorted, setSorted] = useState(true)
  const match = useRouteMatch({
    path: '*/planos/:id'
  })

  const limit = 30

  const { plansRequesting, plans, plansError, totalPages } = useSelector(
    ({ marketplace, plans }) => ({
      plansRequesting: plans.plansRequesting,
      plans: plans.plans,
      plansError: plans.plansError,
      totalPages: plans.totalPages
    })
  )

  useEffect(() => {
    const offset = page * limit

    dispatch(
      getPlans({
        sort: sorted ? 'time-descending' : 'time-ascending',
        collapse: false,
        limit,
        offset
      })
    )

    return () => dispatch(resetPlans())
  }, [sorted, page])

  const handleSortChange = (newSorted) => {
    setPage(0)
    setSorted(newSorted.length && newSorted[0].desc)
  }

  const goDetailPlan = (item) => {
    history.push(`/configuracoes/planos/${item.id}`, { plansId: item.id })
  }

  const onSearchSubmit = (value) => {
    const params = {
      name: value.trim()
    }
    dispatch(getPlans(params))
  }

  const onClearSubmit = () => {
    dispatch(
      getPlans({
        sort: sorted ? 'time-descending' : 'time-ascending',
        collapse: false,
        limit
      })
    )
  }

  const columns = [
    {
      Header: 'Data de criação',
      accessor: 'created_at',
      width: '10%',
      Cell: ({ cell }) => moment(cell.value).format('DD/MM/YYYY'),
      sortable: true
    },
    {
      Header: 'Status',
      accessor: 'is_active',
      width: '15%',
      Cell: ({ cell }) => (
        <Label overflow color={cell.value ? green : yellow}>
          {cell.value ? 'ATIVO' : 'INATIVO'}
        </Label>
      ),
      sortable: false
    },
    {
      Header: 'Nome',
      accessor: 'name',
      width: '25%',
      Cell: ({ cell }) => (
        <Label overflow color={darkGray}>
          {cell.value}
        </Label>
      ),
      sortable: false
    },
    {
      Header: 'ID do Plano',
      accessor: 'id',
      width: '25%',
      Cell: ({ cell }) => (
        <Label overflow color={darkGray}>
          {cell.value}
        </Label>
      ),
      sortable: false
    },
    {
      Header: 'Visível',
      accessor: 'visible',
      width: '10%',
      Cell: ({ cell }) => (
        <Label overflow color={darkGray}>
          {cell.value === '1' ? 'Sim' : 'Não'}
        </Label>
      ),
      sortable: false
    }
  ]

  const emptyStateTitle = 'Não encontramos este plano'
  const emptyStateDescription =
    'Verifique se o nome do plano que você buscou está correto'
  const emptyStateIconPath = '/assets/icons/actions/search.svg'

  if (match && match.params?.id !== 'habilitar-pos') {
    const id = match.params?.id
    return <PlansDetailComponent id={id} />
  }

  return (
    <Grid noPadding>
      <TitleInfoPage>Configurações do marketplace {'>'}</TitleInfoPage>
      <Grid.Row largeMarginBottom>
        <Grid.Col offset={1} cols={15}>
          <Title big noPadding data-test='config-planos-title'>
            <strong>Planos</strong>
          </Title>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col offset={1} cols={15}>
          <SubTitle line>
            Planos <strong>Cadastrados</strong>
          </SubTitle>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col offset={1} cols={4}>
          <SearchInput
            data-tracking={`Plans > Search`}
            onSubmit={onSearchSubmit}
            onClear={onClearSubmit}
            placeholder='Buscar pelo nome do plano'
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col offset={1} cols={15}>
          <Table
            loading={plansRequesting}
            tableColumns={columns}
            tableData={plans}
            pageSize={limit}
            currentPage={page}
            error={plansError}
            totalPages={totalPages}
            onItemClick={goDetailPlan}
            data-tracking={'Plans'}
            onPageChange={(page) => {
              setPage(page)
            }}
            manual
            onSortChange={handleSortChange}
            defaultSortBy={[{ id: 'created_at', desc: true }]}
            emptyState={{
              message: emptyStateTitle,
              description: emptyStateDescription,
              icon: emptyStateIconPath
            }}
          />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  )
}

PlansComponent.propTypes = {
  history: PropTypes.object
}

export default PlansComponent
