export const hints = [
  {
    id: 'select-seller',
    group: 'marketplace',
    isNew: false,
    title: 'Aqui a coisa muda....',
    content:
      'Fácil, fácil, você troca de estabelecimento e acompanha todas as suas movimentações.',
    offset: 20
  },
  {
    id: 'menu-vendas',
    group: 'marketplace',
    isNew: false,
    title: 'Acompanhe suas vendas',
    content:
      'Seja um estorno, uma análise ou até mesmo um detalhe, aqui nesse menu a mágica acontece.',
    offset: 20
  },
  {
    id: 'menu-transferencias',
    group: 'marketplace',
    isNew: false,
    title: 'Histórico de transferências',
    content:
      'Saiba já o que foi pago, falhado e transferidas para o seu marketplace.',
    offset: 20
  },
  {
    id: 'menu-estabelecimentos',
    group: 'marketplace',
    isNew: false,
    title: 'Visão dos estabelecimentos',
    content:
      'Tenha em mãos todos os estabelecimentos habilitados, pendentes ou negados.',
    offset: 20
  },
  {
    id: 'mudar-visao-kpi',
    group: 'marketplace',
    isNew: false,
    title: 'Altere a visão como preferir',
    content:
      'Navegue de forma simples e tenho informações de transações aprovadas, progressão de markup e ticket médio.',
    placement: 'left',
    offset: 20
  },
  {
    id: 'cards-kpis',
    group: 'marketplace',
    isNew: false,
    title: 'Muito além da informação',
    content:
      'Após ter movimento, clicando no ícone você terá uma visão mais analítica do seu negócio. Bom né?',
    placement: 'left',
    offset: 20
  },
  {
    id: 'copie-cole',
    group: 'marketplace',
    isNew: false,
    title: 'Copie e cole',
    content:
      'Se você passar o mouse e aparecer um sombreado, significa que você pode copiar o texto para colar onde quiser.',
    offset: 64
  },
  {
    id: 'markup',
    group: 'marketplace',
    isNew: false,
    title: 'Relatórios de Markup',
    content:
      'Agora você pode baixar seus relatórios de markup direto do Dash!',
    offset: 64
  },
  {
    id: 'voltar-marketplace',
    group: 'seller',
    isNew: false,
    title: 'Retorne para o marketplace',
    content:
      'Aqui você tem toda a visão do estabelecimento, mas é possível voltar a qualquer momento para o marketplace.',
    offset: 64
  },
  {
    id: 'menu-extrato',
    group: 'seller',
    isNew: false,
    title: 'Não deixe de contar nada',
    content:
      'Filtre o tempo que deseja e tenha também a opção de exportar o relatório para consulta.',
    offset: 64
  },
  {
    id: 'menu-lancamentos-futuros',
    group: 'seller',
    isNew: false,
    title: 'O futuro pode ser previsto',
    content:
      'Veja todos os pagamentos previstos tendo uma visão clara do tipo de pagamento valor bruto, líquido e da taxa.',
    offset: 64
  },
  {
    id: 'menu-configuracoes',
    group: 'seller',
    isNew: false,
    title: 'Tenha o controle',
    content:
      'Acesse os dados do estabelecimento, taxas do plano, documento e meios de recebimento.',
    offset: 64
  },
  {
    id: 'fazer-transferencia',
    group: 'seller',
    isNew: false,
    title: 'O dinheiro voa',
    content:
      'É possível de forma fácil visualizar seu saldo e transferir para contas digitais ou bancárias.',
    offset: 64
  },
  {
    id: 'select-seller',
    group: 'seller',
    isNew: false,
    title: 'Estabelecimentos comerciais',
    content:
      'Nesse menu é possível você navegar entre todos os seus estabelecimento comerciais e desbravar todos os mares.',
    offset: 64
  }
]
