import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { createExport, resetCreateExport } from 'src/Actions/exports'

import { Menu as ButtonDefaultDropdown } from 'src/UIComponents/ButtonDropdown/ButtonDefaultDropdown'
import Sidebar from 'src/UIComponents/Sidebar/Sidebar'
import ReportCustomColumns from 'src/UIComponents/Report/ReportCustomColumns'
import ReportButtonNotifier from 'src/UIComponents/ReportButtonNotifier/ReportButtonNotifier'
import Can from 'src/Helpers/Permission/Can'

const ReportButton = ({ type, filters, results }) => {
  const dispatch = useDispatch()
  const { createExportSuccess, createExportError } = useSelector(
    ({ exports }) => exports
  )

  const buttonDisabled = !filters.hasOwnProperty('date_range') || results === 0

  const [selected, setSelected] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const handleChange = (optionValue) => {
    setSelected(optionValue)
    if (optionValue === 0) {
      return dispatch(createExport(type, 'xlsx', filters))
    }
    if (optionValue === 1) {
      return dispatch(createExport(type, 'csv', filters))
    }
    if (optionValue === 2) {
      return setIsOpen(true)
    }
  }

  const handleSubmit = (type, format, columns) => {
    dispatch(createExport(type, format, filters, columns))
  }

  useEffect(() => {
    if (isOpen && createExportSuccess) {
      setIsOpen(false)
    }
  }, [createExportSuccess])

  useEffect(() => {
    return () => {
      dispatch(resetCreateExport())
    }
  }, [isOpen])

  const options = [
    {
      label: 'Exportar em .XLSX',
      value: 0
    },
    {
      label: 'Exportar em .CSV',
      value: 1
    },
    {
      label: 'Personalizar colunas',
      value: 2
    }
  ]

  return (
    <Can do='create' on='ExportTransaction'>
      <Sidebar show={isOpen}>
        <ReportCustomColumns
          handleClose={() => setIsOpen(false)}
          handleSubmit={handleSubmit}
          error={createExportError}
          type={type}
        />
      </Sidebar>
      <ExportButtonContainer>
        <NotifierContainer>
          <ReportButtonNotifier />
        </NotifierContainer>

        <ButtonDefaultDropdown
          label='Exportar relatório'
          selected={selected}
          onChange={handleChange}
          options={options}
          disabled={buttonDisabled}
          data-test='btn-report'
          data-tracking={`Relatório de extrato-${type}`}
          firstControl={results === 0 ? 'Sem resultado para exportar' : ''}
          secondControl={
            !filters.hasOwnProperty('date_range')
              ? 'Antes de seguir, escolha o período de data que deseja exportar'
              : ''
          }
        >
          <strong>Exportar</strong> relatório
        </ButtonDefaultDropdown>
      </ExportButtonContainer>
    </Can>
  )
}

ReportButton.defaultProps = {
  filters: {}
}

ReportButton.propTypes = {
  type: PropTypes.oneOf(['transactions', 'transfers']),
  filters: PropTypes.object,
  results: PropTypes.number
}

const ExportButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const NotifierContainer = styled.div`
  margin-right: 1.5rem;
`

export { ReportButton }
