/* eslint react/prop-types: 0 */
import React, { Fragment } from 'react'
import { darkGray, normalText } from '../../Styles/settings/Constants'

import CardBrandDisplay from '../../UIComponents/CardBrandDisplay/CardBrandDisplay'
import Grid from '../../UIComponents/Grid/Grid'
import Label from '../../UIComponents/Label/Label'
import LineCard from '../../UIComponents/LineCard/LineCard'
import Table from '../Table/NewTable'
import TwiceTitleWithPipe from '../../UIComponents/Title/TwiceTitleWithPipe'
import { sortBy } from 'lodash'
import styled from 'styled-components'

const _formatValue = (percentAmount, dollarAmount) => {
  return percentAmount > 0 && dollarAmount === 0
    ? (percentAmount / 100).toPercentage()
    : percentAmount > 0 && dollarAmount > 0
      ? `${(percentAmount / 100).toPercentage()} + ${(dollarAmount / 100).toMoney()}`
      : (dollarAmount / 100).toMoney()
}

const _formatColumnTax = (row, key) => {
  const item = row.original[key]
  if (item) {
    return (
      <Label color={darkGray} fontSize={normalText} bold>
        {_formatValue(item.percent_amount, item.dollar_amount)}
      </Label>
    )
  } else {
    return (
      <Label color={darkGray} fontSize={normalText} bold>
        -
      </Label>
    )
  }
}

const _getArrayOfInstallments = (items) => {
  if (items && items.length) {
    let installmentsOnline = []
    items.forEach((item) => {
      installmentsOnline.push(...Object.keys(item))
    })

    installmentsOnline = installmentsOnline.filter(
      (value) => value !== 'card_brand'
    )
    installmentsOnline = new Set(installmentsOnline)
    return Array.from(installmentsOnline)
  } else {
    return []
  }
}

export const creditTab = (item, color) => {
  const chips = item.chip && sortBy(item.chip.card_brands, ['card_brand'])
  const online =
    item.manually_keyed &&
    sortBy(item.manually_keyed.card_brands, ['card_brand'])

  const contactless_tap =
    item.contactless_tap &&
    sortBy(item.contactless_tap.card_brands, ['card_brand'])

  const columnsChip = [
    {
      accessor: 'card_brand',
      Cell: ({ cell }) => {
        if (cell.value === 'default') {
          return (
            <Grid>
              <Label color={darkGray} fontSize={normalText} bold>
                Bandeiras padrão
              </Label>
            </Grid>
          )
        }
        return <CardBrandDisplay center src={cell.value} />
      },
      sortable: false
    }
  ]

  const columnsOnline = [
    {
      accessor: 'card_brand',
      Cell: ({ cell }) => {
        if (cell.value === 'default') {
          return (
            <Grid>
              <Label color={darkGray} fontSize={normalText} bold>
                Bandeiras padrão
              </Label>
            </Grid>
          )
        }
        return <CardBrandDisplay center src={cell.value} />
      },
      sortable: false
    }
  ]

  const columnsContactlessTap = [
    {
      accessor: 'card_brand',
      Cell: ({ cell }) => {
        if (cell.value === 'default') {
          return (
            <Grid>
              <Label color={darkGray} fontSize={normalText} bold>
                Bandeiras padrão
              </Label>
            </Grid>
          )
        }
        return <CardBrandDisplay center src={cell.value} />
      },
      sortable: false
    }
  ]

  let installmentsChip = _getArrayOfInstallments(chips)

  installmentsChip.forEach((item) => {
    columnsChip.push({
      id: `${item}x`,
      Header: item === '1' ? 'à vista' : `${item}x`,
      Cell: ({ row }) => _formatColumnTax(row, item),
      sortable: false,
      width: '80px'
    })
  })

  let installmentsOnline = _getArrayOfInstallments(online)

  installmentsOnline.forEach((item) => {
    columnsOnline.push({
      id: `${item}x`,
      Header: item === '1' ? 'à vista' : `${item}x`,
      Cell: ({ row }) => _formatColumnTax(row, item),
      sortable: false,
      width: '80px'
    })
  })

  let installmentsContactlessTap = _getArrayOfInstallments(contactless_tap)

  installmentsContactlessTap.forEach((item) => {
    columnsContactlessTap.push({
      id: `${item}x`,
      Header: item === '1' ? 'à vista' : `${item}x`,
      Cell: ({ row }) => _formatColumnTax(row, item),
      sortable: false,
      width: '80px'
    })
  })

  return (
    <Fragment>
      {chips && (
        <Container>
          <Grid.Row auto>
            <LineCard
              noVerticalMargin
              status='neutral'
              backgroundColor={color}
              data-test='seller-detail-plan-detail-fisicas'
              id='fisicas'
              label={
                <TwiceTitleWithPipe
                  titleOne='Crédito Vendas Físicas'
                  titleTwo={
                    item.chip.settlement_days > 1 ? (
                      <>
                        Recebimento em{' '}
                        <strong>{item.chip.settlement_days} dias</strong>
                      </>
                    ) : (
                      <>
                        Recebimento em{' '}
                        <strong>{item.chip.settlement_days} dia</strong>
                      </>
                    )
                  }
                />
              }
            >
              <Table
                tableColumns={columnsChip}
                tableData={chips}
                showPagination={false}
              />
            </LineCard>
          </Grid.Row>
        </Container>
      )}
      {online && (
        <Container>
          <Grid.Row auto>
            <LineCard
              noVerticalMargin
              status='neutral'
              backgroundColor={color}
              id='online'
              data-test='seller-detail-plan-detail-online'
              label={
                <TwiceTitleWithPipe
                  titleOne='Crédito Vendas Online'
                  titleTwo={
                    item.manually_keyed.settlement_days > 1 ? (
                      <>
                        Recebimento em{' '}
                        <strong>
                          {item.manually_keyed.settlement_days} dias
                        </strong>
                      </>
                    ) : (
                      <>
                        Recebimento em{' '}
                        <strong>
                          {item.manually_keyed.settlement_days} dia
                        </strong>
                      </>
                    )
                  }
                />
              }
            >
              <Table
                tableColumns={columnsOnline}
                tableData={online}
                showPagination={false}
              />
            </LineCard>
          </Grid.Row>
        </Container>
      )}
      {contactless_tap && (
        <Container>
          <Grid.Row auto>
            <LineCard
              noVerticalMargin
              status='neutral'
              backgroundColor={color}
              id='contactless_tap'
              data-test='seller-detail-plan-detail-contactless_tap'
              label={
                <TwiceTitleWithPipe
                  titleOne='Crédito Tap to Pay'
                  titleTwo={
                    item.contactless_tap.settlement_days > 1 ? (
                      <>
                        Recebimento em{' '}
                        <strong>
                          {item.contactless_tap.settlement_days} dias
                        </strong>
                      </>
                    ) : (
                      <>
                        Recebimento <strong>instantâneo</strong>
                      </>
                    )
                  }
                />
              }
            >
              <Table
                tableColumns={columnsContactlessTap}
                tableData={contactless_tap}
                showPagination={false}
              />
            </LineCard>
          </Grid.Row>
        </Container>
      )}
    </Fragment>
  )
}

export const debitTab = (item, color) => {
  const debits = item.chip && sortBy(item.chip.card_brands, ['card_brand'])
  const contactless_tap =
    item.contactless_tap &&
    sortBy(item.contactless_tap.card_brands, ['card_brand'])

  const columns = [
    {
      accessor: 'card_brand',
      Cell: ({ cell }) => {
        if (cell.value === 'default') {
          return (
            <Grid>
              <Label color={darkGray} fontSize={normalText} bold>
                Bandeiras padrão
              </Label>
            </Grid>
          )
        }
        return <CardBrandDisplay center src={cell.value} />
      },
      width: '10%',
      sortable: false
    },
    {
      id: '1x',
      Header: 'à vista',
      Cell: ({ row }) => _formatColumnTax(row, 1),
      sortable: false
    }
  ]

  const columnsContactlessTap = [
    {
      accessor: 'card_brand',
      Cell: ({ cell }) => {
        if (cell.value === 'default') {
          return (
            <Grid>
              <Label color={darkGray} fontSize={normalText} bold>
                Bandeiras padrão
              </Label>
            </Grid>
          )
        }
        return <CardBrandDisplay center src={cell.value} />
      },
      width: '14%',
      sortable: false
    },
    {
      id: '1x',
      Header: 'à vista',
      Cell: ({ row }) => _formatColumnTax(row, 1),
      sortable: false
    }
  ]

  return (
    <Fragment>
      {debits && (
        <Container>
          <Grid.Row auto>
            <LineCard
              noVerticalMargin
              status='neutral'
              backgroundColor={color}
              id='debito'
              data-test='seller-detail-plan-detail-debito'
              label={
                <TwiceTitleWithPipe
                  titleOne='Débito'
                  titleTwo={
                    item.chip.settlement_days > 1 ? (
                      <>
                        Recebimento em{' '}
                        <strong>{item.chip.settlement_days} dias</strong>
                      </>
                    ) : (
                      <>
                        Recebimento em{' '}
                        <strong>{item.chip.settlement_days} dia</strong>
                      </>
                    )
                  }
                />
              }
            >
              <Table
                tableColumns={columns}
                tableData={debits}
                showPagination={false}
              />
            </LineCard>
          </Grid.Row>
        </Container>
      )}
      {contactless_tap && (
        <Container>
          <Grid.Row auto>
            <LineCard
              noVerticalMargin
              status='neutral'
              backgroundColor={color}
              id='contactless_tap_debito'
              data-test='seller-detail-plan-detail-contactless_tap_debito'
              label={
                <TwiceTitleWithPipe
                  titleOne='Débito Tap to Pay'
                  titleTwo={
                    item.contactless_tap.settlement_days > 1 ? (
                      <>
                        Recebimento em{' '}
                        <strong>
                          {item.contactless_tap.settlement_days} dias
                        </strong>
                      </>
                    ) : (
                      <>
                        Recebimento <strong>instantâneo</strong>
                      </>
                    )
                  }
                />
              }
            >
              <Table
                tableColumns={columnsContactlessTap}
                tableData={contactless_tap}
                showPagination={false}
              />
            </LineCard>
          </Grid.Row>
        </Container>
      )}
    </Fragment>
  )
}

export const boletoTab = (item, color) => {
  const pays = item.chip && item.chip.card_brands
  const columns = [
    {
      id: 'tax_label',
      Cell: () => (
        <Label color={darkGray} fontSize={normalText} bold>
          Tarifa por boleto:
        </Label>
      ),
      sortable: false
    },
    {
      id: 'tax_value',
      Cell: ({ row }) => _formatColumnTax(row, 1),
      sortable: false
    }
  ]

  return (
    <Fragment>
      {pays && (
        <Container>
          <Grid.Row auto>
            <LineCard
              noVerticalMargin
              status='neutral'
              backgroundColor={color}
              id='boleto'
              data-test='seller-detail-plan-detail-boleto'
              label={
                <TwiceTitleWithPipe
                  titleOne='Boleto Bancário'
                  titleTwo={
                    item.chip.settlement_days > 1 ? (
                      <>
                        Recebimento em{' '}
                        <strong>{item.chip.settlement_days} dias</strong>
                      </>
                    ) : (
                      <>
                        Recebimento em{' '}
                        <strong>{item.chip.settlement_days} dia</strong>
                      </>
                    )
                  }
                />
              }
            >
              <Table
                tableColumns={columns}
                tableData={pays}
                showPagination={false}
                noDisplayHeader
              />
            </LineCard>
          </Grid.Row>
        </Container>
      )}
    </Fragment>
  )
}

export const pixTab = (item, color) => {
  const pays = item.barcode && item.barcode.card_brands
  const columns = [
    {
      id: 'tax_label',
      Cell: () => (
        <Label color={darkGray} fontSize={normalText} bold>
          Taxa por transação:
        </Label>
      ),
      maxWidth: 150,
      sortable: false
    },
    {
      id: 'tax_value',
      Cell: ({ row }) => _formatColumnTax(row, 1),
      minWidth: 72,
      sortable: false
    }
  ]

  return (
    <Fragment>
      {pays && (
        <Container>
          <Grid.Row auto>
            <LineCard
              noVerticalMargin
              status='neutral'
              backgroundColor={color}
              id='pix'
              data-test='seller-detail-plan-detail-pix'
              label={
                <TwiceTitleWithPipe
                  titleOne='Pix'
                  titleTwo={
                    item.barcode.settlement_days > 1 ? (
                      <>
                        Recebimento em{' '}
                        <strong>{item.barcode.settlement_days} dias</strong>
                      </>
                    ) : (
                      <>
                        Recebimento em{' '}
                        <strong>{item.barcode.settlement_days} dia</strong>
                      </>
                    )
                  }
                />
              }
            >
              <Table
                tableColumns={columns}
                tableData={pays}
                iconComponent={null}
                showPagination={false}
                noDisplayHeader
              />
            </LineCard>
          </Grid.Row>
        </Container>
      )}
    </Fragment>
  )
}

export const bolepixTab = (item, color) => {
  const pays = item.chip && item.chip.card_brands
  const columns = [
    {
      id: 'tax_label',
      Cell: () => (
        <Label color={darkGray} fontSize={normalText} bold>
          Taxa por transação:
        </Label>
      ),
      maxWidth: 150,
      sortable: false
    },
    {
      id: 'tax_value',
      Cell: ({ row }) => _formatColumnTax(row, 1),
      minWidth: 72,
      sortable: false
    }
  ]

  return (
    <Fragment>
      {pays && (
        <Container>
          <Grid.Row auto>
            <LineCard
              noVerticalMargin
              status='neutral'
              backgroundColor={color}
              id='pix'
              data-test='seller-detail-plan-detail-pix'
              label={
                <TwiceTitleWithPipe
                  titleOne='Boleto com pix'
                  titleTwo={
                    item.chip.settlement_days > 1 ? (
                      <>
                        Recebimento em{' '}
                        <strong>{item.chip.settlement_days} dias</strong>
                      </>
                    ) : (
                      <>
                        Recebimento em{' '}
                        <strong>{item.chip.settlement_days} dia</strong>
                      </>
                    )
                  }
                />
              }
            >
              <Table
                tableColumns={columns}
                tableData={pays}
                iconComponent={null}
                showPagination={false}
                noDisplayHeader
              />
            </LineCard>
          </Grid.Row>
        </Container>
      )}
    </Fragment>
  )
}

export const nupayTab = (item, color) => {
  const credit =
    item.credit_manually_keyed &&
    sortBy(item.credit_manually_keyed.card_brands, ['card_brand'])
  const debit =
    item.debit_manually_keyed &&
    sortBy(item.debit_manually_keyed.card_brands, ['card_brand'])

  const columnsCredit = []

  const columnsDebit = []

  let installmentsCredit = _getArrayOfInstallments(credit)

  installmentsCredit.forEach((item) => {
    columnsCredit.push({
      id: `${item}x`,
      Header: item === '1' ? 'à vista' : `${item}x`,
      Cell: ({ row }) => _formatColumnTax(row, item),
      sortable: false,
      width: '80px'
    })
  })

  let installmentsDebit = _getArrayOfInstallments(debit)

  installmentsDebit.forEach((item) => {
    columnsDebit.push({
      id: `${item}x`,
      Header: item === '1' ? 'à vista' : `${item}x`,
      Cell: ({ row }) => _formatColumnTax(row, item),
      sortable: false,
      width: '80px'
    })
  })

  return (
    <Fragment>
      {debit && (
        <Container>
          <Grid.Row auto>
            <LineCard
              noVerticalMargin
              status='neutral'
              backgroundColor={color}
              id='online'
              data-test='seller-detail-plan-detail-online'
              label={
                <TwiceTitleWithPipe
                  titleOne='Nupay Débito Online'
                  titleTwo={
                    item.debit_manually_keyed.settlement_days > 1 ? (
                      <>
                        Recebimento em{' '}
                        <strong>
                          {item.debit_manually_keyed.settlement_days} dias
                        </strong>
                      </>
                    ) : (
                      <>
                        Recebimento em{' '}
                        <strong>
                          {item.debit_manually_keyed.settlement_days} dia
                        </strong>
                      </>
                    )
                  }
                />
              }
            >
              <Table
                tableColumns={columnsDebit}
                tableData={debit}
                showPagination={false}
              />
            </LineCard>
          </Grid.Row>
        </Container>
      )}
      {credit && (
        <Container>
          <Grid.Row auto>
            <LineCard
              noVerticalMargin
              status='neutral'
              backgroundColor={color}
              data-test='seller-detail-plan-detail-fisicas'
              id='fisicas'
              label={
                <TwiceTitleWithPipe
                  titleOne='Nupay Crédito Online'
                  titleTwo={
                    item.credit_manually_keyed.settlement_days > 1 ? (
                      <>
                        Recebimento em{' '}
                        <strong>
                          {item.credit_manually_keyed.settlement_days} dias
                        </strong>
                      </>
                    ) : (
                      <>
                        Recebimento em{' '}
                        <strong>
                          {item.credit_manually_keyed.settlement_days} dia
                        </strong>
                      </>
                    )
                  }
                />
              }
            >
              <Table
                tableColumns={columnsCredit}
                tableData={credit}
                showPagination={false}
              />
            </LineCard>
          </Grid.Row>
        </Container>
      )}
    </Fragment>
  )
}

export const loadingTax = () => (
  <Fragment>
    <Container>
      <Grid.Row auto>
        <LineCard isLoading />
      </Grid.Row>
    </Container>
    <Container>
      <Grid.Row auto>
        <LineCard isLoading />
      </Grid.Row>
    </Container>
  </Fragment>
)

const Container = styled.div`
  width: 100%;
  margin: 0 0 4rem 0;
  padding: 0;
`
