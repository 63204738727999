import React, { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Grid from 'src/UIComponents/Grid/Grid'
import InputDefaultFactory from 'src/Components/FormFactories/InputDefaultFactory'
import FormZ from 'src/UIComponents/FormZ/FormZ'
import t from 'tcomb-form'
import formValidators from 'src/Utils/formValidators'
import PersonalDataTemplate from 'src/UIComponents/TemplatesForm/PersonalDataTemplate'
import Button from 'src/UIComponents/Button/ButtonDefault'
import { getFormOptionsWithValidation } from 'src/Utils/FormUtils'
import { updateLoggedInUser } from 'src/Actions/user'
import ChangePassCard from 'src/Components/ChangePassSidebar/ChangePassCard'
import { MFASingleComponent } from '../MFA/mfaSingleComponent'

const MyProfileComponent = () => {
  const {
    id,
    username,
    firstName,
    lastName,
    updateUserRequesting,
    updateUserSuccess
  } = useSelector(({ user }) => ({
    id: user.id,
    username: user.username,
    firstName: user.firstName,
    lastName: user.lastName,
    updateUserRequesting: user.updateUserRequesting,
    updateUserSuccess: user.updateUserSuccess
  }))

  const [changePassCardOpen, setChangePassCardOpen] = useState(false)
  const [personalDataViewMode, setPersonalDataViewMode] = useState('view')
  const [personalDataShowValidation, setPersonalDataShowValidation] =
    useState(false)

  const [showMFA, setShowMFA] = useState(false)
  const [personalDataValues, setPersonalDataValues] = useState({
    firstName,
    lastName,
    username
  })

  useEffect(() => {
    if (updateUserSuccess) {
      setPersonalDataValues({
        firstName,
        lastName,
        username
      })

      setPersonalDataViewMode('view')
    }
  }, [updateUserSuccess])

  const dispatch = useDispatch()

  const isPersonalDataViewMode = () => personalDataViewMode === 'view'

  const personalDataOptions = {
    template: (locals) =>
      PersonalDataTemplate(
        locals,
        togglePersonalDataViewMode,
        personalDataViewMode
      ),
    fields: {
      firstName: {
        label: 'Nome',
        factory: InputDefaultFactory,
        config: {
          id: 'personalData-firstName',
          placeholder: ' ',
          disabled: isPersonalDataViewMode()
        }
      },
      lastName: {
        label: 'Sobrenome',
        factory: InputDefaultFactory,
        config: {
          id: 'personalData-lastName',
          placeholder: ' ',
          disabled: isPersonalDataViewMode()
        }
      },
      username: {
        label: 'E-mail',
        factory: InputDefaultFactory,
        config: {
          id: 'personalData-username',
          disabled: true,
          lock: isPersonalDataViewMode()
        }
      }
    }
  }

  const personalDataStruct = t.struct({
    firstName: formValidators.Name,
    lastName: formValidators.Name,
    username: t.maybe(t.String)
  })

  const togglePersonalDataViewMode = () =>
    setPersonalDataViewMode(isPersonalDataViewMode() ? 'edit' : 'view')

  const handlePersonalDataValuesChange = (values) => {
    const verifiedValues = {
      ...values,
      firstName: values.firstName && values.firstName.trimLeft(),
      lastName: values.lastName && values.lastName.trimLeft()
    }

    setPersonalDataShowValidation(false)
    setPersonalDataValues(verifiedValues)
  }

  const cancelPersonalDataEdit = () => {
    setPersonalDataShowValidation(false)
    setPersonalDataViewMode('view')
    setPersonalDataValues({
      firstName,
      lastName,
      username
    })
  }

  const handleOpenMFA = () => {
    if (!t.validate(personalDataValues, personalDataStruct).isValid()) {
      setPersonalDataShowValidation(true)
      return
    }
    setShowMFA(true)
  }

  const submitPersonalData = () => {
    if (!t.validate(personalDataValues, personalDataStruct).isValid()) {
      setPersonalDataShowValidation(true)
      return
    }

    const { firstName, lastName } = personalDataValues

    dispatch(
      updateLoggedInUser(id, {
        profile: {
          first_name: firstName,
          last_name: lastName
        }
      })
    )
  }

  return (
    <Fragment>
      {showMFA && (
        <MFASingleComponent
          isSidebar
          showComponent={setShowMFA}
          onSubmit={submitPersonalData}
        />
      )}
      <Grid.Row />
      <Grid.Row largeMarginBottom>
        <Grid.Col offset={1} cols={15}>
          <FormZ
            options={
              personalDataShowValidation
                ? getFormOptionsWithValidation(
                    personalDataOptions,
                    personalDataValues,
                    personalDataStruct
                  )
                : personalDataOptions
            }
            struct={personalDataStruct}
            values={personalDataValues}
            onChange={handlePersonalDataValuesChange}
            viewMode={isPersonalDataViewMode()}
            marginBottom
          />
        </Grid.Col>
      </Grid.Row>
      {!isPersonalDataViewMode() && (
        <Grid.Row largeMarginBottom>
          <Grid.Col offset={1} cols={5}>
            <Button
              inversed
              disabled={updateUserRequesting}
              onClick={cancelPersonalDataEdit}
              margin='0.5rem'
            >
              Cancelar
            </Button>
          </Grid.Col>
          <Grid.Col cols={7}>
            <Button
              isLoading={updateUserRequesting}
              type='submit'
              onClick={handleOpenMFA}
            >
              Salvar Alterações
            </Button>
          </Grid.Col>
        </Grid.Row>
      )}
      {changePassCardOpen && isPersonalDataViewMode() && (
        <Grid.Row>
          <Grid.Col offset={1} cols={14}>
            <ChangePassCard
              handleCloseCard={() => setChangePassCardOpen(false)}
            />
          </Grid.Col>
        </Grid.Row>
      )}
      {!changePassCardOpen && isPersonalDataViewMode() && (
        <Grid.Row>
          <Grid.Col offset={1} cols={6}>
            <Button
              inversed
              type='submit'
              data-test='my-profile-change-password'
              onClick={() => setChangePassCardOpen(true)}
            >
              Alterar Senha
            </Button>
          </Grid.Col>
        </Grid.Row>
      )}
    </Fragment>
  )
}

export default MyProfileComponent
